@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.room-dropdown {
    padding: 1rem;
    width: 100%;
    max-width: 19.375rem;
    &__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        margin-bottom: 1rem;
        cursor: pointer;
    }
    &__cube-icon {
        margin-left: 0.5rem;
    }
    &__title {
        margin-left: 1rem;
        font-family: $font-family-inter;
        font-size: $font18;
        font-weight: 700;
        color: $color-darker-blue;
    }

    &__item {
        padding-left: 5.6rem;
        max-width: 11.6rem;
        overflow: hidden;
        min-width: 8rem;
    }

    &__link--closed {
        display: none;
    }
}
