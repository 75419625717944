@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.radio {
    @include radio-button();
    &:hover {
        cursor: pointer;
    }

    &__input {
        position: absolute;
        opacity: 0;
        margin: 0;
        cursor: pointer;
    }

    &__span {
        width: $radio-width;
        height: $radio-height;
        border-radius: $radio-radius;
        position: absolute;
        top: 0;
        left: 0;
        @include radio-button();
        background-color: $color-light-grey;

        &--red {
            @include radio-button-checked();
            @include radio-button();
            background-color: $color-red;
        }

        &--dark {
            @include radio-button-checked();
            @include radio-button();
            background-color: $color-dark-blue;
        }
    }
}
