@import "../abstracts/variables.scss";

.header {
    display: flex;
    flex-direction: row;
    top: 0;
    width: 100%;
    height: 7.188rem;
    background-color: $color-white;
    border-bottom: 0.047rem solid $color-mid-dark-grey;
    z-index: $z-index-first-layer;

    @media screen and (max-width: $breakpoint-1439) {
        height: 5.8rem;
    }

    @media screen and (max-width: $breakpoint-1023) {
        height: 3.75rem;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-left: 6.5%;
        margin-right: 18%;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1919) {
            margin-right: 14%;
        }

        @media screen and (max-width: $breakpoint-1599) {
            margin-right: 10%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__search-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        width: 33%;

        @media screen and (max-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__menu {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 100%;
        width: 37%;
        z-index: $z-index-second-layer;

        @media screen and (max-width: $breakpoint-1024) {
            width: 50%;
            justify-content: center;
        }

        @media screen and (max-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__menu-home {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 12%;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1365) {
            padding-left: 9%;
        }

        @media screen and (max-width: $breakpoint-1023) {
            padding-left: 5%;
        }
    }

    &__menu-notifications {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 12%;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1023) {
            padding-left: 9%;
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 3.25rem;
        width: 3.25rem;
        border-radius: 50%;
        color: transparent;
        margin-bottom: 0.3rem;

        &:hover {
            background-color: $color-grey;
        }
    }

    &__text {
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font14;
        color: $color-darker-blue;
    }

    &__profile-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 12%;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1023) {
            padding-left: 9%;
        }
    }

    &__menu-profile {
        background-color: $color-white;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 5.75rem;
        height: 3.688rem;
        border-radius: 1.844rem;

        &:hover {
            filter: brightness(0.95);
        }
    }

    &__image {
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 50%;
    }

    &__mobile-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 20%;
        cursor: pointer;

        @media screen and (min-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__mobile-search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48%;
        height: 100%;

        @media screen and (min-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__mobile-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 32%;
        height: 100%;

        @media screen and (min-width: $breakpoint-1023) {
            display: none;
        }
    }

    &__burger-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 21.5%;
        height: 100%;

        @media screen and (min-width: $breakpoint-1023) {
            display: none;
        }
    }
}
