@import "../abstracts/variables.scss";

.single-text-input {
    &__text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-darker-blue;
        padding-left: 1rem;
        margin-bottom: 0.63rem;
    }

    &--margin-top-1 {
        margin-top: 1.2rem;
    }

    &--margin-top-2 {
        margin-top: 2rem;
    }

    &--padding-left-2 {
        padding-left: 2rem;

        @media screen and (max-width: $breakpoint-768) {
            padding-left: 0rem;
        }
    }

    &__error-text {
        max-width: 11rem;
        color: $color-red;
        margin-left: 1rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        margin-bottom: 1rem;
    }
}
