@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.user-profile-experience-item {
    width: 100%;
    height: 4.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: solid 0.03rem $color-grey;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-768) {
        height: 4rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        height: 3rem;
    }

    &__job-container {
        width: 50%;
        display: flex;
        flex-direction: row;
        margin-left: 0.75rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 7rem;
        }
    }

    &__position {
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font16;
        padding-right: 1rem;
        width: fit-content;
        max-width: 13rem;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font14;
        }
    }

    &__company {
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font16;
        padding-right: 1rem;
        width: fit-content;
        max-width: 10rem;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }

    &__date-container {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0.75rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 10rem;
        }
    }

    &__date {
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font14;

        @media screen and (max-width: $breakpoint-550) {
            font-size: $font12;
        }
    }
}
