@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.dropdown-element {
    font-family: $font-family-inter;
    font-size: $font18;
    font-weight: 500;
    color: $color-dark-grey;
    margin-bottom: 1rem;
    margin-left: 5.5rem;
}
