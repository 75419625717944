@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.user-profile-card {
    width: 100%;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    box-shadow: $box-shadow-overlay;
    border-radius: $border-radius-09;

    &__search-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 8rem;
        margin-bottom: 2rem;
    }

    &__search-options {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    &__search-option-item {
        height: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: $font16;
        font-weight: 700;
        font-family: $font-family-inter;
        color: $color-mid-dark-grey;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
        }
    }

    &__search-option-selected {
        border-bottom: solid 0.2rem $color-light-blue;
        color: $color-light-blue;
    }

    &__search-option-unselected {
        border-bottom: solid 0.2rem transparent;
    }

    &__search-input {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &__content {
        height: 75%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25%;
        width: 96%;
    }
}
