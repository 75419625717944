@import "../abstracts/variables.scss";

.notifications-card {
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3.75rem;
    border-bottom: solid 0.03rem $color-mid-dark-grey;
    cursor: pointer;

    @media screen and (max-width: 1439px) {
        height: 3.35rem;
    }

    &:hover {
        filter: brightness(0.95);
    }

    &__left-panel {
        display: flex;
        justify-content: center;
        width: 18.9%;
        align-items: center;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: center;
        width: 58%;
    }

    &__rigt-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-blue;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-dark-blue;
        align-self: flex-start;
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-blue;
        align-self: flex-start;
    }

    &__right-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-blue;
    }
}
