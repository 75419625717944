@import "../abstracts/variables.scss";

.feed-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30rem;
    min-width: 19rem;
    margin: 1rem;

    @media screen and (max-width: $breakpoint-1962) {
        width: 28rem;
    }
    @media screen and (max-width: $breakpoint-1841) {
        width: 26rem;
    }
    @media screen and (max-width: $breakpoint-1763) {
        width: 24rem;
    }
    @media screen and (max-width: $breakpoint-1610) {
        width: 22rem;
    }
    @media screen and (max-width: $breakpoint-1599) {
        width: 32rem;
    }
    &--padding-1 {
        padding: 1rem;
    }

    &__text-container {
        max-height: 9rem;
        max-height: 11rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
        overflow-y: hidden;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-darker-blue;
    }
}

.feed-item-room-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 6.125rem;
    border-bottom: solid 0.03rem $color-grey;

    @media screen and (max-width: $breakpoint-1599) {
        height: 7rem;
    }
    &__left-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 20%;
        margin-right: 1.5rem;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        overflow: hidden;
    }

    &__right-panel {
        display: flex;
        justify-content: flex-end;
        width: 30%;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font24;
        font-weight: 700;
        color: $color-red;

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font18;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;
    }

    &__right-text {
        font-family: $font-family-inter;
        margin: 0.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.188rem;
        background-color: $color-gold;
        padding-left: 0.36rem;
        padding-right: 0.36rem;
        font-weight: 100;
        width: max-content;
        border-radius: 0.9rem;
        font-size: $font10;
        color: $color-white;
        text-align: center;
    }
}

.feed-item-profile-card {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5rem;

    &__left-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 14%;
        margin-right: 1rem;
    }

    &__middle-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 72%;
    }

    &__right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 14%;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 700;
        color: $color-darker-blue;
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 500;
        color: $color-dark-grey;
        margin-right: 0.3rem;
    }

    &__right-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: max-content;
        background-color: $color-grey;
        padding-left: 0.36rem;
        padding-right: 0.36rem;
        padding-top: 0.15rem;
        padding-bottom: 0.15rem;
        font-weight: 100;
        width: max-content;
        border-radius: 0.9rem;
        font-size: $font12;
        color: $color-red;
        text-align: center;
        margin-left: 0.75rem;
        margin-right: 0.3rem;
    }
}
