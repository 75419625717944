@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.new-members-card {
    display: flex;
    flex-direction: column;
    width: 95%;

    max-width: 19.375rem;
    min-width: 14rem;
    padding-top: 1.625rem;
    padding-bottom: 1.875rem;

    &__members {
        margin-top: 0.75rem;
        align-self: flex-start;
        overflow: hidden;
    }

    &__button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.75rem;
    }

    &__button {
        width: 100%;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
}

.new-members-item {
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    height: 4.5rem;
    width: 100%;
    border-bottom: 0.031rem solid $color-mid-dark-grey;

    &:hover {
        filter: brightness(0.95);
    }

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
    }

    &__right-panel {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 20%;
    }

    &__text {
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font14;
        color: $color-darker-blue;
    }

    &__subtext {
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font12;
        color: $color-blue;
    }

    &__right-text {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 500;
        color: $color-blue;
    }
}
