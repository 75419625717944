@import "../abstracts/variables.scss";

.h1 {
    font-size: $font36;
}

.h2 {
    font-size: $font28;
}

.h3 {
    font-size: $font24;
}

.h4 {
    font-size: $font18;
}

.h5 {
    font-size: $font14;
}

.p1 {
    font-size: $font16;
}

.p2 {
    font-size: $font14;
}

.p3 {
    font-size: $font12;
}
