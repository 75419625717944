@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.settings-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__form {
        height: 60%;
        max-width: 31rem;
    }

    &__button {
        height: 12%;
        display: flex;
        justify-content: flex-end;

        &--padding-right-2 {
            padding-right: 2rem;
        }
    }
}
