@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.profile-rooms-item {
    height: 4.75rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: solid 0.03rem $color-grey;
    cursor: pointer;

    @media screen and (max-width: $breakpoint-768) {
        height: 4.25rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        height: 3.75rem;
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 5rem;
        height: 3.125rem;
        margin-left: 0.5rem;

        @media screen and (max-width: $breakpoint-550) {
            width: 4rem;
        }
    }

    &__organization-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font16;
        margin-left: 0.5rem;
        width: 9rem;

        &__overflow {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
        }
    }

    &__type {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font10;
        color: $color-white;
        margin-left: 0.5rem;
        width: 4rem;

        @media screen and (max-width: $breakpoint-450) {
            display: none;
        }
    }

    &__type-container {
        background: $color-blue-opacity-04;
        border-radius: $border-radius-09;
    }

    &__room-name {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font14;
        margin-left: 0.5rem;
        width: 9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &__overflow {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &__members {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font14;
        color: $color-gold;
        margin-left: 0.5rem;
        width: 7.5rem;

        @media screen and (max-width: $breakpoint-1350) {
            display: none;
        }
        @media screen and (max-width: $breakpoint-1199) {
            font-size: $font12;
        }

        @media screen and (max-width: $breakpoint-1023) {
            display: flex;
        }
        @media screen and (max-width: $breakpoint-660) {
            display: none;
        }
    }

    &__members-container {
        background: $color-gold-opacity-02;
        border-radius: $border-radius-09;
    }

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-darker-blue;
        font-family: $font-family-inter;
        font-weight: 500;
        font-size: $font14;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        width: 5.5rem;

        @media screen and (max-width: $breakpoint-1279) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-1023) {
            display: flex;
        }
        @media screen and (max-width: $breakpoint-660) {
            display: none;
        }

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font12;
        }
    }

    &__text {
        padding-left: 0.35rem;
        padding-right: 0.35rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
}
