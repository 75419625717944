@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.search-input {
    display: flex;
    flex-direction: row;
    max-width: 39.438rem;
    min-width: 18rem;
    width: 100%;
    height: 2.5rem;
    background-color: $color-grey;
    border-radius: 0.625rem;
    align-content: center;
    border: 0.03rem solid transparent;

    @media screen and (max-width: $breakpoint-660) {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        height: 2.25rem;
    }

    &:hover {
        border: 0.03rem solid $color-mid-dark-grey;
    }

    &__search-field {
        height: 80%;
        width: 90%;
        border: none;
        background-color: transparent;
        border-radius: 0.625rem;
        align-self: center;
        margin-left: 1.5rem;
        font-family: $font-family-inter;

        &:focus {
            outline: none;
        }
    }

    &__search-icon {
        display: flex;
        justify-content: center;
        height: 90%;
        width: 10%;
        align-items: center;

        @media screen and (max-width: $breakpoint-1350) {
            padding-right: 0.5rem;
        }
    }
}
