@import "../abstracts/variables.scss";

.image {
    &__cover {
        border-radius: $image-cover-border-radius;
        object-fit: $image-cover-object-fit;

        &--size-small {
            width: $image-cover-small-width;
            height: $image-cover-small-height;
        }

        &--size-large {
            width: $image-cover-large-width;
            height: $image-cover-large-height;
        }
    }

    &__profile {
        border-radius: $border-radius-circular;

        &--fit {
            object-fit: $image-cover-object-fit;
        }

        &--size-small {
            width: $image-profile-small-width;
            height: $image-profile-small-height;
        }

        &--size-medium {
            width: $image-profile-medium-width;
            height: $image-profile-medium-height;
        }

        &--size-large {
            width: $image-profile-large-width;
            height: $image-profile-large-height;
        }
    }
}
