@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.settings-privacy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 87%;
    width: 100%;

    &__form {
        height: 60%;
    }

    &__button {
        height: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &--padding-right-2 {
            padding-right: 2rem;
        }
    }

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 32%;
        height: 4rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-darker-blue;
        margin-bottom: 1.5rem;
    }

    &__checkbox {
        font-family: $font-family-inter;
        font-size: $font14;
        font-weight: 400;
        color: $color-darker-blue;
        width: 80%;
        height: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 5.5rem;
    }
}
