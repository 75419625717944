@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.delete-account {
    width: 100%;
    height: 90%;
    font-family: $font-family-inter;
    color: $color-dark-blue;

    &__title {
        @include titles;
        margin-bottom: 1.75rem;
    }

    &__information-title {
        @include titles;

        margin-bottom: 1.75rem;
    }

    &__text-area-title {
        @include titles;
        margin-bottom: 1.313rem;
    }

    &__form {
        margin-left: 1.125rem;
        width: 24.625rem;
    }

    &__information-container {
        margin-bottom: 2rem;
        width: 91%;
        height: 7.5rem;
    }

    &__information {
        font-size: $font12;
        margin-bottom: 0.938rem;
    }

    &__text-area {
        width: 94%;
        height: 167px;
        resize: none;
        background-color: $color-grey;
        border-radius: 0.5rem;
        border: transparent;
        color: $color-dark-blue;
    }

    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        margin-bottom: 2.81rem;
        font-family: $font-family-inter;
    }

    &__checkbox {
        display: flex;
    }

    &__checkbox-text {
        font-size: $font12;
        width: 93%;
        margin: 0;
        align-self: center;
    }

    &__button {
        height: 10%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 10.375rem;
        &--padding-right {
            padding-right: 2rem;
        }
    }
}
