@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.user-profile-description {
    position: relative;
    margin-top: 2rem;
    height: 22.75rem;
    width: 90%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    box-shadow: $box-shadow-overlap;
    border-radius: $border-radius-09;
    margin-bottom: 2.5rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1439) {
        height: 20rem;
    }

    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
        height: 22.75rem;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
        height: 18rem;
        margin-bottom: 1rem;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 100%;
        height: 20.5rem;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 0;
    }

    &__content {
        height: 90%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__icon {
        @include edit-icon;

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }
    &__small-icon {
        @include edit-icon;

        @media screen and (min-width: $breakpoint-551) {
            display: none;
        }
    }

    &__divider {
        height: 0.03rem;
        width: 100%;
        background-color: $color-grey;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 45%;
        background-color: $color-white;

        @media screen and (max-width: $breakpoint-550) {
            width: 90%;
            height: 48%;
        }
    }
}
