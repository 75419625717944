@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.text-input {
    @include input();
    height: 3.75rem;
    position: relative;
    display: flex;
    border: 0.031rem solid $color-grey;

    &:hover {
        border: $text-input-hover-border;
        cursor: pointer;
    }

    &__element {
        @include input();
        height: 3.3rem;
        cursor: pointer;
        position: absolute;
        left: 1.4rem;

        &:focus {
            outline: none;
        }

        &--extra-small {
            height: 2.25rem;
            width: 10.625rem;
            @media screen and (max-width: $breakpoint-768) {
                width: 12.625rem;
            }
        }

        &--small {
            max-width: 12rem;
            @media screen and (max-width: $breakpoint-1919) {
                width: 10rem;
                height: 2.8rem;
            }
            @media screen and (max-width: $breakpoint-1599) {
                width: 7.5rem;
                height: 2.4rem;
            }
            @media screen and (max-width: $breakpoint-768) {
                width: 17rem;
                height: 2.7rem;
            }
            @media screen and (max-width: $breakpoint-340) {
                width: 13rem;
            }
        }
        &--medium {
            width: 23.188rem;
            @media screen and (max-width: $breakpoint-768) {
                width: 17rem;
                height: 2.7rem;
            }
            @media screen and (max-width: $breakpoint-340) {
                width: 13rem;
            }
        }

        &--large-40 {
            height: 2.25rem;
            width: 23.563rem;
            @media screen and (max-width: $breakpoint-768) {
                width: 12.625rem;
            }
        }

        &--large {
            width: 27.563rem;
            @media screen and (max-width: $breakpoint-1919) {
                width: 22.96rem;
                height: 2.8rem;
            }
            @media screen and (max-width: $breakpoint-1599) {
                width: 19.7rem;
                height: 2.4rem;
            }
            @media screen and (max-width: $breakpoint-768) {
                width: 17rem;
                height: 2.7rem;
            }
            @media screen and (max-width: $breakpoint-340) {
                width: 13rem;
            }
        }
    }

    &--extra-small {
        height: 2.5rem;
        width: 12.5rem;
        @media screen and (max-width: $breakpoint-768) {
            width: 15.625rem;
        }
    }

    &--small {
        width: 14.5rem;
        @media screen and (max-width: $breakpoint-1919) {
            width: 12.5rem;
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            width: 11rem;
            height: 2.7rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }

    &--medium {
        width: 25rem;
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }

    &--large-40 {
        height: 2.5rem;
        width: 26.963rem;
        @media screen and (max-width: $breakpoint-768) {
            width: 15.625rem;
        }
    }

    &--large {
        width: 32rem;
        @media screen and (max-width: $breakpoint-1919) {
            width: 28rem;
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            width: 24rem;
            height: 2.7rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }
}

textarea:focus {
    outline: none;
}
