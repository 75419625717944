@import "../abstracts/variables.scss";

.header-profile-menu {
    width: 15.625rem;
    height: 23rem;
    top: 7rem;
    position: absolute;
    border-radius: 1rem;

    @media screen and (max-width: $breakpoint-1439) {
        width: 13rem;
        top: 5.7rem;
        height: 20rem;
    }
    @media screen and (max-width: $breakpoint-1279) {
        right: 1rem;
    }

    &__card {
        max-width: 15.625rem;
        height: 21.5rem;
        padding-top: 1rem;

        @media screen and (max-width: $breakpoint-1439) {
            height: 19.25rem;
        }
    }

    &__profile {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 6.2rem;
        border-bottom: 0.03rem solid $color-light-grey;
        background-color: $color-white;
        cursor: pointer;

        &:hover {
            filter: brightness(0.95);
        }
    }
    &__image {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &__profile-text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
    }

    &__item {
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.55rem;
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 500;
        color: $color-dark-blue;
        cursor: pointer;

        @media screen and (max-width: $breakpoint-1439) {
            height: 3rem;
        }

        &:hover {
            filter: brightness(0.95);
        }

        &--border-bottom-3 {
            border-bottom: 0.03rem solid $color-light-grey;
        }

        &--hover-color-red {
            &:hover {
                background-color: $color-red;
            }
        }

        &--text-color-white {
            &:hover {
                color: $color-white;
            }
        }
    }
}
