@import "../abstracts/variables.scss";

.email-confirmation {
    font-family: $font-family-inter;
    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
    &__title {
        margin-top: 12.349rem;
        margin-bottom: 1.375rem;
        color: $color-blue;
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 5rem;
        }
    }
    &__description {
        max-width: 26.5rem;
        margin-bottom: 2.438rem;
        color: $color-dark-blue;
    }
    &__container-text {
        padding-left: 1rem;
        padding-bottom: 0.75rem;
        color: $color-dark-blue;
    }
    &__email-card {
        width: 25rem;
        height: 3.75rem;
        background-color: $color-grey;
        color: $color-dark-blue;
        border-radius: 0.938rem;
        position: relative;
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            height: 3rem;
            margin-bottom: 5rem;
        }
    }
    &__email {
        position: absolute;
        left: 1.25rem;
        top: 1.438rem;
        @media screen and (max-width: $breakpoint-768) {
            top: 1.2rem;
        }
    }
}

.tick-icon {
    position: absolute;
    left: 20.75rem;
    right: 1.411rem;
    @media screen and (max-width: $breakpoint-768) {
        left: 14.75rem;
        top: -0.375rem;
    }
}
