@import "../abstracts/variables.scss";

.accordion {
    max-width: 100%;

    &__selector {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        height: 5.125rem;
        width: $accordion__selector-width;
        @media screen and (max-width: $breakpoint-1023) {
            width: 18.75rem;
            height: 3rem;
        }
    }

    &__title {
        color: $color-darker-blue;
        font-weight: 700;
        margin: 0;
        bottom: 1.5rem;
        font-family: $font-family-inter;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
        }
    }

    &__button {
        margin-left: 1rem;
        bottom: 1.5rem;

        &:hover {
            cursor: pointer;
        }
    }

    &__container {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 43.75rem;
        justify-content: flex-start;
        height: fit-content;
        //max-height: 9.688rem;
        margin-bottom: 2rem;
        @media screen and (max-width: $breakpoint-1023) {
            flex-direction: column;
        }
    }

    &__text {
        display: flex;
        padding-left: 1.5rem;
        font-family: $font-family-inter;
        padding-bottom: 0.75rem;
        color: $color-dark-blue;
        @media screen and (max-width: $breakpoint-1023) {
            justify-content: flex-start;
            padding-left: 1rem;
        }
    }
    &__checkbox {
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        width: 70%;
        margin-left: 2.188rem;
        margin-bottom: 0.8rem;
        @media screen and (max-width: $breakpoint-1919) {
            margin-bottom: 0;
        }

        @media screen and (max-width: $breakpoint-1023) {
            margin-top: 1.188rem;
            width: 100%;
        }
    }

    &__checkbox-text {
        font-family: $font-family-inter;
        color: $color-dark-blue;
        margin: 0;
        width: 100%;
        height: 2.813rem;
        padding-left: 1.25rem;

        @media screen and (max-width: $breakpoint-1023) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }
}

.tooltipIcon {
    position: absolute;
    top: 1.1rem;
    left: 18.8rem;
    @media screen and (max-width: $breakpoint-1023) {
        width: 12px;
        height: 12px;
        left: 11.125rem;
        top: 0.1rem;
    }
}

.orangeVector {
    @media screen and (max-width: $breakpoint-1023) {
        width: 13px;
        height: 7px;
    }
}
