@import "../abstracts/variables.scss";

.double-text-input {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 1.2rem;

    @media screen and (max-width: $breakpoint-768) {
        flex-direction: column;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        color: $color-darker-blue;
        padding-left: 1rem;
        margin-bottom: 0.63rem;
    }

    &__error-text {
        max-width: 11rem;
        color: $color-red;
        margin-left: 1rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
        font-size: $font12;
        font-weight: 400;
        margin-bottom: 1rem;
    }
}
