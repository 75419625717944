@import "../abstracts/variables.scss";

.signin-form {
    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
    &__single-input {
        margin-bottom: 1.56rem;
    }

    &__title {
        display: block;
        margin-top: 8.125rem;
        text-decoration: none;
        color: $color-blue;
        font-weight: 700;
        margin-top: 0.6rem;
        font-family: $font-family-inter;

        &--margin-top-8 {
            margin-top: 8.125rem;
        }
        &--margin-top-4 {
            margin-top: 4.438rem;
            margin-bottom: 4.438rem;
        }

        &__single-input {
            margin-bottom: 1.56rem;
        }
    }

    &__small-text {
        font-family: $font-family-inter;
        margin-left: 1rem;
        color: $color-dark-blue;
        font-weight: 400;
        margin-bottom: 0.75rem;
    }

    &__forgot-password {
        font-family: $font-family-inter;
        text-decoration: none;
        margin-left: 1rem;
        color: $color-blue;
        font-weight: 400;
    }

    &__button {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        font-family: $font-family-inter;
        margin-top: 1.8rem;

        @media screen and (max-width: $breakpoint-768) {
            justify-content: flex-end;
        }
    }

    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
}
