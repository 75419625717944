.heartIcon {
    padding-right: 1.94rem;
}

.plusIcon {
    padding-right: 0.625rem;
}

.bookmarkIconSmall {
    padding-right: 0.5rem;
}

.chatIcon {
    padding-right: 0.625rem;
}

.bookmarkIcon {
    padding-right: 0.625rem;
}
