@import "../abstracts/variables.scss";

p {
    margin: 0;
}

.edusiiamatching-form {
    width: 100%;
    font-family: $font-family-inter;
    color: $color-darker-blue;
    @media screen and (max-width: $breakpoint-768) {
        width: 30.5rem;

        margin-top: 30%;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 25.5rem;
        margin: auto;
        margin-top: 30%;
    }
    @media screen and (max-width: $breakpoint-450) {
        width: 19.5rem;
        margin: auto;
        margin-top: 40%;
    }
    @media screen and (max-width: $breakpoint-350) {
        width: 17.5rem;
        margin: auto;
        margin-top: 40%;
    }

    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }
    &__radio-conainer {
        width: 100%;
    }

    &__title {
        margin-top: 16%;
        margin-bottom: 4%;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: 1.25rem;
        }
    }

    &__radio-text {
        font-family: $font-family-inter;
        font-size: $font18;
        width: 100%;
        margin-bottom: 5%;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font14;
        }
    }

    &__radio-yes {
        display: flex;
        align-items: baseline;
        p {
            font-size: $font18;
            padding-left: 1.25rem;
            @media screen and (max-width: $breakpoint-1023) {
                font-size: $font14;
                align-self: center;
            }
        }
    }

    &__radio-no {
        display: flex;
        align-items: baseline;
        margin-top: 4.5%;
        margin-bottom: 4.5%;
        p {
            font-size: $font18;
            padding-left: 1.25rem;
            @media screen and (max-width: $breakpoint-1023) {
                font-size: $font14;
                align-self: center;
            }
        }
    }

    &__checkbox-privacy {
        display: flex;
        margin-top: 1.188rem;
        align-items: baseline;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font12;
            margin-top: 2.313rem;
        }
    }

    &__checkbox-privacy-text {
        padding-left: 1.25rem;
    }

    &__link {
        text-decoration: none;
        color: $color-blue;
    }

    &__checkbox-newsletter {
        display: flex;
        margin-top: 2.313rem;
        margin-bottom: 3.688rem;
        align-items: baseline;
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font12;
            flex-direction: row;
        }
    }

    &__checkbox-newsletter-text {
        padding-left: 1.25rem;
    }

    &__error-message {
        color: $color-red;
        padding-left: 1.25rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }

    &__button {
        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-bottom: 5rem;
        }
    }
}

.logo-icon {
    padding-left: 1rem;
}
