@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.sponsor-card {
    display: inline-block;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    min-width: 14rem;
    max-width: 19.375rem;
    padding-top: 1.875rem;

    &__slider-card {
        width: 100%;
        min-width: 14.7rem;
        max-width: 19.375rem;
        height: fit-content;
        margin-bottom: 1rem;
        white-space: nowrap;
        transform: translateX(0);
        transition: transform 1s ease;
    }
    &__container {
        display: flex;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    &__content {
        background: $color-white;
        box-shadow: $card-box-shadow;
        border-radius: 0.62rem;
        text-overflow: ellipsis;
    }

    &__organization {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 5%;
    }

    &__organization-image {
        margin-left: 9%;
        align-self: flex-start;
    }

    &__organization-name {
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 700;
        align-self: center;
        margin-left: 0.938rem;
    }

    &__title {
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 9%;
        margin-bottom: 0.25rem;
        font-family: $font-family-inter;
        font-size: $font20;
        color: $color-blue;
        font-weight: 700;
        width: 14rem;

        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font18;
            width: 12rem;
        }
    }
    &__bold-title {
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }

    &__sub-title {
        padding-left: 0.38rem;
        font-weight: 300;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
    }

    &__description {
        font-family: $font-family-inter;
        margin-left: 9%;
        font-weight: 500;
        font-size: $font12;
        color: $color-dark-blue;
        word-break: break-all;
        text-overflow: ellipsis;
        margin-bottom: 0.375rem;
        @media screen and (max-width: $breakpoint-1700) {
            font-size: $font10;
            width: 12rem;
        }
    }

    &__event-image {
        width: 90%;
        height: 47%;
        justify-items: center;
        margin-top: 0.6rem;
        margin: auto;
        padding-bottom: 3%;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.95rem;

        @media screen and (max-width: $breakpoint-1700) {
            margin-bottom: 0.5rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            margin-bottom: 0;
        }
        @media screen and (max-width: $breakpoint-1365) {
            margin-bottom: 0.5rem;
        }
    }
}
