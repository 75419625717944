@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.dropdown {
    @include dropdown();

    &:hover {
        cursor: pointer;
    }

    &__select {
        @include dropdown();
        @include alignText();
        height: 3.875rem;
        background-color: $color-grey;
        position: relative;

        @media screen and (max-width: $breakpoint-1919) {
            height: 3.2rem;
        }
        @media screen and (max-width: $breakpoint-1599) {
            height: 2.9rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            height: 3rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 15.125rem;
            font-size: 0.813rem;
        }
    }

    &__icon {
        position: absolute;
        right: 1.563rem;
        @media screen and (max-width: $breakpoint-340) {
            right: 0.8rem;
        }
    }

    &__container {
        @include dropdown();
        @include container();
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            margin: 0;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }

    &__content {
        @include dropdown();
        @include container();
        @media screen and (max-width: $breakpoint-768) {
            width: 18.75rem;
            margin: 0;
        }
        @media screen and (max-width: $breakpoint-340) {
            width: 17rem;
        }
    }
    &__item {
        @include item();
        border-bottom: 0.031rem solid $color-mid-dark-grey;

        &--selected-true {
            @include item();
            background-color: $color-grey;
            border-bottom: 0.031rem solid $color-mid-dark-grey;
        }
    }

    &__text {
        @include alignText();
        margin-top: 0.7rem;
    }
}

::-webkit-scrollbar {
    width: 0.375rem;
}
::-webkit-scrollbar-track {
    background: $color-mid-dark-grey;
}
::-webkit-scrollbar-thumb {
    background: $color-grey-c4;
}
