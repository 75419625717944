@import "../abstracts/variables.scss";

.notifications-dropdown {
    top: 7rem;
    position: absolute;
    width: 23.75rem;
    max-height: 33.813rem;
    scrollbar-width: none;

    @media screen and (max-width: 1439px) {
        width: 20rem;
        top: 5.7rem;
    }
    @media screen and (max-width: 1199px) {
        width: 18.5rem;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    &__card {
        padding-top: 1rem;
        padding-bottom: 1rem;
        max-height: 33.813rem;
        max-width: 23.75rem;
        overflow: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
    }
}
