@import "../abstracts/variables.scss";

.user-profile-experience-screen {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    background-color: transparent;
    margin-bottom: 5rem;
    min-width: 20rem;

    @media screen and (max-width: $breakpoint-768) {
        background-color: $color-white;
    }
}