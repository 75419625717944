@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.user-profile-description-item {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-red;
        font-size: $font16;
        font-weight: 700;
        margin-bottom: 1.5rem;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font14;
            width: 85%;
            margin-bottom: 1rem;
        }
    }

    &__text {
        width: 100%;
        font-family: $font-family-inter;
        color: $color-darker-blue;
        font-size: $font14;
        font-weight: 500;
        overflow-y: hidden;
        white-space: normal;

        @media screen and (max-width: $breakpoint-768) {
            font-size: $font12;
        }
    }
}
