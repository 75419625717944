@import "../abstracts/variables.scss";

.FAQ-Accordion {
    border-bottom: 0.031rem solid $color-mid-dark-grey;
    font-family: $font-family-inter;
    &__label {
        border: none;
        width: 90%;
        padding-top: 1.313rem;
        padding-bottom: 1.375rem;
        font-weight: 500;
        display: flex;
        font-size: $font18;
        color: $color-darker-blue;
        background-color: $color-white;
        text-align: start;
        margin-left: 0.5rem;
        @media screen and (max-width: $breakpoint-1200) {
            font-size: $font16;
            font-weight: 500;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font14;
        }
    }
    &__label:hover {
        cursor: pointer;
    }

    &__icon {
        padding-left: 2rem;
        padding-right: 1.563rem;
    }
    &__content--inactive {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    &__content--active {
        max-height: 100vh;
        overflow: hidden;
        padding-bottom: 1.25rem;
        transition: all 0.4s ease-in-out;
    }
    &__item--inactive {
        max-height: 0;
        transition: max-height 0.3s ease-in-out;
        color: $color-dark-blue;
        font-size: $font16;
        padding-bottom: 0.313rem;
        margin: auto;
        @media screen and (max-width: $breakpoint-1200) {
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font12;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 60%;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 55%;
        }
        width: 75%;
    }
    &__item--active {
        width: 75%;
        color: $color-dark-blue;
        font-size: $font16;
        padding-bottom: 0.313rem;
        margin: auto;
        @media screen and (max-width: $breakpoint-1200) {
            font-size: $font14;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font12;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-768) {
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 60%;
        }
        @media screen and (max-width: $breakpoint-350) {
            width: 55%;
        }
    }
}
