@import "../abstracts/variables.scss";

.button {
    display: flex;
    border: 1px;
    display: inline-block;
    cursor: pointer;

    &--margin-bottom-1 {
        margin-bottom: 1rem;
    }

    &--darker-hover:hover {
        filter: brightness(0.95);
    }

    &--lighter-hover:hover {
        filter: brightness(1.1);
    }

    &__content {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-family: $font-family-inter;
        font-weight: 400;
    }

    &--small {
        border-radius: 0.5rem;
        height: 2.8rem;
        width: 8.75rem;
    }

    &--medium {
        border-radius: 0.95rem;
        height: 3.88rem;
        width: 13.1rem;

        @media screen and (max-width: $breakpoint-1919) {
            height: 3.1rem;
            width: 10.5rem;
        }

        @media screen and (max-width: $breakpoint-1599) {
            height: 2.7rem;
            width: 9.2rem;
        }
        @media screen and (max-width: $breakpoint-340) {
            height: 2.625rem;
            width: 7.5rem;
        }
    }

    &--large {
        border-radius: 0.75rem;
        height: 2.8rem;
        width: 100%;
    }

    &--extra-wide {
        width: 15.6rem;
    }

    &--color-red {
        background-color: $color-red;
    }

    &--color-grey {
        background-color: $color-grey;
    }

    &--color-blue {
        background-color: $color-blue;
    }

    &--color-gold {
        background-color: $color-gold;
    }

    &--text-color-white {
        color: $color-white;
    }

    &--text-color-grey {
        color: $color-dark-grey;
    }
}

.button:disabled {
    cursor: initial;
}

.icon-button {
    font-family: $font-family-inter;
    display: flex;
    border: 1px;
    display: inline-block;
    cursor: pointer;
    width: 100%;

    &:hover {
        filter: brightness(1.1);
    }

    &__content {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-family: center;
        font-weight: 400;
    }

    &--small {
        height: 1.875rem;
        border-radius: 0.56rem;
    }

    &--medium {
        height: 3.125rem;
        border-radius: 0.95rem;
    }

    &--large {
        min-width: 12.5rem;
        height: 3.44rem;
        border-radius: 0.95rem;
        font-size: $font18;
    }

    &--color-red {
        background-color: $color-red;
    }

    &--color-grey {
        background-color: $color-grey;
    }

    &--color-blue {
        background-color: $color-blue;
    }

    &--color-gold {
        background-color: $color-gold;
    }

    &--text-color-white {
        color: $color-white;
    }
}
