@import "../abstracts/variables.scss";

//radio-button
@mixin radio-button {
    position: relative;
    content: "";
    display: inline-block;
    visibility: visible;
    font-family: $font-family-inter;
    font-size: $radio-font-size;
    font-weight: $radio-font-weight;
}

@mixin radio-button-checked {
    width: $radio-checked-width;
    height: $radio-checked-height;
    border: $radio-border-checked;
    border-radius: $radio-radius;
    position: absolute;
    top: 0;
    left: 0;
}

//checkbox

@mixin clicked {
    width: 0.875rem;
    height: 0.875rem;
    border: $radio-border-checked;
}

//dropdown

@mixin dropdown {
    margin: auto;
    border-radius: $dropdown-radius;
    font-family: $font-family-inter;
    font-size: $font14;
    max-width: 33.375rem;
    color: $color-dark-blue;
    @media screen and (max-width: 768px) {
        width: 16.875rem;
        margin: 0;
    }
}

@mixin alignText {
    display: flex;
    align-items: center;
    padding-left: 1.875rem;
    margin-block-end: 0;
}

@mixin container {
    max-height: 10rem;
    box-shadow: $dropdown-box-shadow;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

@mixin item {
    height: 2.5rem;
    max-width: 33.375rem;
    position: relative;
    display: flex;
    flex-direction: column;
}

//textinput

@mixin input {
    background-color: $color-grey;
    border-radius: 0.938rem;
    border: $text-input-border;
    color: $color-dark-blue;
    font-size: $font14;
    font-family: $font-family-inter;
}

//delete-account

@mixin titles {
    font-size: $font14;
    font-weight: 500;
}

@mixin edit-icon {
    position: absolute;
    top: 0.1rem;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circular;
    width: 3.5rem;
    height: 3.5rem;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: $color-grey;
    }
}

@mixin camera-icon {
    position: absolute;
    z-index: $z-index-first-layer;
    top: 0.1rem;
    right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius-circular;
    width: 3.5rem;
    height: 3.5rem;
    background-color: transparent;
    cursor: pointer;

    &:hover {
        background-color: $color-white-opacity-20;
    }
}
