@import "../abstracts/variables.scss";

.profile-card {
    max-width: 19.375rem;
    width: 100%;
    min-width: 14rem;
    &__container {
        max-width: 19.375rem;
        width: 100%;
        min-width: 14rem;
        height: 6.875rem;
        margin-bottom: 1.5rem;
    }
}

.item {
    justify-self: center;
    align-self: center;
    width: 100%;
    border-radius: 0.95rem;
    background-color: $color-white;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &__left-panel {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 6rem;
    }

    &__text {
        font-family: $font-family-inter;
        font-size: $font16;
        font-weight: 700;
        color: $color-darker-blue;
    }

    &__subtext {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 0.438rem;
        height: 1.188rem;
        background-color: $tag-background-color-blue;
        padding-left: 0.36rem;
        padding-right: 0.36rem;
        padding-top: 0.2rem;
        width: max-content;
        border-radius: 0.9rem;
        font-size: $font12;
        color: $color-blue;
        text-align: center;
    }
}
