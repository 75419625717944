@import "../abstracts/variables.scss";

.header-mobile-profile-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__left-panel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
    }

    &__middle-panel {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-content: center;
        height: 60%;
        width: 67%;
    }

    &__image {
        border-radius: 50%;
        object-fit: cover;
        width: 4rem;
        height: 4rem;

        @media screen and (max-width: $breakpoint-420) {
            width: 3rem;
            height: 3rem;
        }
    }

    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.438rem;
        height: 1.188rem;
        padding-left: 0.36rem;
        padding-right: 0.36rem;
        font-weight: 100;
        width: max-content;
        border-radius: $border-radius-09;
        font-size: $font10;
        color: $color-white;
        text-align: center;

        &--background-color-red {
            background-color: $color-red;
        }
        &--background-color-gold {
            background-color: $color-gold;
        }
    }

    &__subtext {
        font-family: $font-family-inter;
        font-size: $font16;
        color: $color-darker-blue;

        &--font-weight-400 {
            font-weight: 400;
        }

        &--font-weight-700 {
            font-weight: 700;
        }
    }
}
