//general

$font-family-inter: "Inter", sans-serif;
$color-white: white;
$color-red: #d57467;
$color-dark-blue: #375558;
$color-darker-blue: #1c3e42;
$color-light-grey: #e5e5e5;
$color-mid-dark-grey: #c5c5c5;
$color-grey-c4: #c4c4c4;
$color-grey: #f4f4f4;
$color-light-blue: #89bcb9;
$color-gold: #e09f53;
$color-dark-grey: #687373;
$color-blue: #60a49f;
$color-light-blue: #54bcca;
$color-darker-blue: #1c3e42;
$color-twitter-blue: #1da1f2;
$color-linkedin-blue: #0077b5;
$color-white-opacity-20: rgba(100, 100, 100, 0.2);
$color-opacity-15: rgba(84, 188, 202, 0.15);
$color-gold-opacity-01: rgba(255, 199, 0, 0.1);
$color-gold-opacity-02: rgba(255, 199, 0, 0.2);
$color-blue-opacity-04: rgba(96, 164, 159, 0.4);
$color-white: white;
$tag-background-color-blue: rgba(84, 188, 202, 0.15);
$box-shadow-overlap: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.25);
$hover-brightness: brightness(1.1);
$hover-brightness-darker: brightness(0.95);
$box-shadow-overlay: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.25);
$font36: 2.25rem;
$font32: 2rem;
$font30: 1.875rem;
$font28: 1.75rem;
$font24: 1.5rem;
$font22: 1.375rem;
$font20: 1.25rem;
$font18: 1.125rem;
$font16: 1rem;
$font14: 0.875rem;
$font12: 0.75rem;
$font10: 0.625rem;
$font-family-Caudex: "Caudex";
$border-radius-circular: 50%;
$border-radius-09: 0.9rem;
$breakpoint-1919: 1919px;
$breakpoint-1962: 1962px;
$breakpoint-1900: 1900px;
$breakpoint-1841: 1841px;
$breakpoint-1800: 1800px;
$breakpoint-1763: 1763px;
$breakpoint-1700: 1700px;
$breakpoint-1610: 1610px;
$breakpoint-1600: 1600px;
$breakpoint-1599: 1599px;
$breakpoint-1500: 1500px;
$breakpoint-1439: 1439px;
$breakpoint-1403: 1403px;
$breakpoint-1400: 1400px;
$breakpoint-1365: 1365px;
$breakpoint-1350: 1350px;
$breakpoint-1280: 1280px;
$breakpoint-1279: 1279px;
$breakpoint-1200: 1200px;
$breakpoint-1199: 1199px;
$breakpoint-1099: 1099px;
$breakpoint-1050: 1050px;
$breakpoint-1024: 1024px;
$breakpoint-1023: 1023px;
$breakpoint-819: 819px;
$breakpoint-768: 768px;
$breakpoint-769: 769px;
$breakpoint-660: 660px;
$breakpoint-551: 551px;
$breakpoint-550: 550px;
$breakpoint-450: 450px;
$breakpoint-420: 420px;
$breakpoint-350: 350px;
$breakpoint-340: 340px;
$z-index-first-layer: 1;
$z-index-second-layer: 2;
$z-index-third-layer: 3;
$z-index-forth-layer: 4;

//footer
$footer-height: 12.5rem;

//images
$image-profile-border-radius: 50%;
$image-profile-small-width: 3rem;
$image-profile-small-height: 3rem;
$image-profile-medium-width: 4.25rem;
$image-profile-medium-height: 4.25rem;
$image-profile-large-width: 7.8rem;
$image-profile-large-height: 7.8rem;
$image-cover-border-radius: 94rem;
$image-cover-large-width: 99rem;
$image-cover-small-width: 50rem;
$image-cover-large-height: 15.2rem;
$image-cover-small-height: 14.6rem;
$image-cover-object-fit: cover;

//radio
$checkmark-radius: 0.5rem;
$radio-width: 1.25rem;
$radio-height: 1.25rem;
$radio-radius: 0.5rem;
$radio-top: -0.125rem;
$radio-left: -0.63rem;
$radio-checked-width: 0.875rem;
$radio-checked-height: 0.875rem;
$radio-border-checked: 0.188rem solid #f7f7f7;
$radio-font-size: 1.125rem;
$radio-font-weight: 500;

//tooltip
$tooltip-margin: 1.88rem;
$tooltip-arrow-size: 0.38rem;
$tooltip-tip-transform: translateX(0) translateY(-50%);
$tooltip-border-radius: 0.69rem;
$tooltip-padding: 0.94rem;
$tooltip-font-size: 0.725rem;
$tooltip-line-height: 1;
$tooltip-white-space: nowrap;
$tooltip-z-index: $z-index-first-layer;

//dropdown
$dropdown-radius: 0.938rem;
$dropdown-width: 33.375rem;
$accordion__selector-width: 43.875rem;
$dropdown-box-shadow: 0rem 0rem 0.25rem rgba(0, 0, 0, 0.2);

//auth
$auth-screen-width: 120rem;
$auth-screen-height: 80rem;
$auth-main-container-height: calc(80rem - $footer-height);
$auth-form-margin-top: 8.25rem;
$auth-form-margin-left: 15.813rem;

//card
$card-border-radius: 0.62rem;
$card-box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.26);

//textinput

$text-input-border: 0.031rem solid #f4f4f4;
$text-input-hover-border: 0.031rem solid #dedede;
