@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.profile-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    height: 23.5rem;
    width: 90%;
    max-width: 50rem;
    background-color: transparent;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
    }
    @media screen and (max-width: $breakpoint-550) {
        width: 100%;
        height: 18rem;
    }
    @media screen and (max-width: $breakpoint-350) {
        height: 16.5rem;
    }

    &__cover-image-container {
        position: relative;
        min-width: 100%;
        max-width: 100%;
        height: 14.625rem;
        border-radius: $border-radius-09;
        background-color: transparent;

        @media screen and (max-width: $breakpoint-1439) {
            height: 12.625rem;
        }

        @media screen and (max-width: $breakpoint-350) {
            height: 10.625rem;
        }
    }
    &__cover-image {
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
        min-height: 100%;
        object-fit: cover;
        border-radius: $border-radius-09;

        @media screen and (max-width: $breakpoint-550) {
            border-radius: 0;
        }
    }

    &__camera-icon {
        @include camera-icon;

        @media screen and (max-width: 550px) {
            display: none;
        }
    }

    &__camera-small-icon {
        @include camera-icon;

        @media screen and (min-width: 551px) {
            display: none;
        }
    }

    &__profile-image {
        position: absolute;
        width: 7.8rem;
        height: 7.8rem;
        border-radius: $border-radius-circular;
        left: 7%;
        top: 12rem;
        cursor: pointer;
        object-fit: $image-cover-object-fit;

        @media screen and (max-width: $breakpoint-1599) {
            left: 5%;
        }

        @media screen and (max-width: $breakpoint-1439) {
            top: 10rem;
        }

        @media screen and (max-width: $breakpoint-1199) {
            width: 6.5rem;
            height: 6.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 7.8rem;
            height: 7.8rem;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 6.5rem;
            height: 6.5rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            width: 5rem;
            height: 5rem;
        }
        @media screen and (max-width: $breakpoint-350) {
            top: 8rem;
            width: 4.5rem;
            height: 4.5rem;
        }
    }

    &__user-name {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        height: 2.75rem;
        width: 72%;
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-darker-blue;
        font-size: $font24;
        padding-right: 1rem;
        padding-left: 1rem;
        background-color: transparent;

        @media screen and (max-width: $breakpoint-1599) {
            font-size: $font22;
        }

        @media screen and (max-width: $breakpoint-1365) {
            width: 68%;
            font-size: $font20;
            height: 2.5rem;
        }
        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font24;
            width: 72%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-660) {
            font-size: $font20;
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-450) {
            font-size: $font16;
            width: 65%;
            height: 2.5rem;
        }
    }

    &__edit-icon {
        @include edit-icon;

        @media screen and (max-width: 550px) {
            display: none;
        }
    }

    &__edit-small-icon {
        @include edit-icon;

        @media screen and (min-width: 551px) {
            display: none;
        }
    }

    &__tags-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 72%;
        height: 2.5rem;
        padding-right: 1rem;
        padding-left: 1rem;

        @media screen and (max-width: $breakpoint-1365) {
            width: 68%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 72%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-660) {
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 65%;
            height: 2rem;
        }
    }

    &__location-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 72%;
        height: 2rem;
        padding-right: 1rem;
        padding-left: 1rem;

        @media screen and (max-width: $breakpoint-1365) {
            width: 68%;
        }
        @media screen and (max-width: $breakpoint-1023) {
            width: 72%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-819) {
            width: 70%;
        }
        @media screen and (max-width: $breakpoint-660) {
            width: 65%;
        }
        @media screen and (max-width: $breakpoint-550) {
            width: 65%;
            height: 1.5rem;
        }
    }

    &__location {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 8rem;
        font-family: $font-family-inter;
        font-weight: 500;
        color: $color-dark-grey;
        font-size: $font14;
    }

    &__others-view {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0.5rem;
        width: 9.375rem;
        height: 3.125rem;
        border-radius: $border-radius-09;
        border: solid 0.03rem $color-gold;
        background-color: $color-gold-opacity-01;
        font-family: $font-family-inter;
        font-weight: 700;
        color: $color-gold;
        font-size: $font14;
        cursor: pointer;

        &:hover {
            background-color: $color-gold-opacity-02;
        }

        @media screen and (max-width: $breakpoint-550) {
            display: none;
        }
    }
}
