@import "../abstracts/variables.scss";

body {
    margin: 0;
}

.footer {
    z-index: $z-index-first-layer;
    bottom: 0;
    width: 100%;
    border-top: 0.047rem solid $color-mid-dark-grey;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 12.5rem;
    background-color: $color-white;

    @media screen and (max-width: $breakpoint-1050) {
        display: block;
    }

    &__content {
        height: 100%;
        width: 75%;
        max-width: 90rem;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $breakpoint-1403) {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__links {
        line-height: 1.188rem;
        height: 1.188rem;
        display: flex;

        @media screen and (max-width: $breakpoint-1050) {
            width: 100%;
            height: 50%;
            justify-content: center;
            align-items: center;
        }

        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        height: 1.188rem;
        font-family: $font-family-inter;
        font-weight: 400;
        font-size: $font16;
        color: $color-dark-blue;
        text-decoration: none;
        margin-left: 0.375rem;
        margin-right: 0.375rem;
        &:hover {
            color: $color-light-blue;
        }
    }

    &__line {
        color: $color-blue;
    }
    &__social-media {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    &__linkedin-icon {
        margin: 1.813rem;
        @media screen and (max-width: $breakpoint-1403) {
            margin-bottom: 0.5rem;
        }
    }
    &__icon-line {
        border-right: 0.063rem solid $color-light-grey;
        height: 1.813rem;
        @media screen and (max-width: $breakpoint-1403) {
            margin-top: 1.5rem;
        }
    }
    &__twitter-icon {
        margin: 1.813rem;
        @media screen and (max-width: $breakpoint-1403) {
            margin-bottom: 0.5rem;
        }
    }
}

.twitter-icon :hover {
    fill: $color-twitter-blue;
}

.linkedin-icon :hover {
    fill: $color-linkedin-blue;
}
