@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.dashboard-feed {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    height: 100%;
    margin-bottom: 3rem;
    max-width: 72rem;
    min-width: 20rem;
    margin: auto;

    &__header {
        width: 92%;
        margin: auto;
        @media screen and (max-width: $breakpoint-1599) {
            width: 78%;
        }
    }

    &__text {
        font-family: $font-family-inter;
        color: $color-darker-blue;

        &--font-size-36 {
            font-size: $font36;
        }

        &--font-size-18 {
            font-size: $font18;
        }

        &--font-weight-700 {
            font-weight: 700;
        }

        &--font-weight-500 {
            font-weight: 500;
        }

        &--margin-bottom-06 {
            margin-bottom: 0.65rem;
        }

        &--margin-bottom-27 {
            margin-bottom: 2.7rem;
        }
    }

    &__content {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
