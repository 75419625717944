@import "../abstracts/variables.scss";

.signup-form {
    font-family: $font-family-inter;
    width: 100%;

    &__logo {
        @media screen and (max-width: $breakpoint-768) {
            display: none;
        }
    }

    &__title {
        color: $color-darker-blue;
        font-weight: 700;
        font-size: $font28;

        @media screen and (max-width: $breakpoint-1919) {
            font-size: $font24;
        }

        &--big-margin {
            margin-top: 3.375rem;
        }

        &--small-margin {
            margin-top: 2.188rem;
        }
    }

    &__small-text {
        margin-left: 1rem;
        margin-bottom: 0.6rem;
        color: $color-darker-blue;
        font-weight: 400;
        @media screen and (max-width: $breakpoint-768) {
            margin-top: 1.3rem;
        }
    }

    &__double-input-container {
        width: 100%;
        display: flex;
        object-fit: contain;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1.55rem;
        @media screen and (max-width: $breakpoint-768) {
            flex-direction: column;
        }
    }

    &__single-input {
        display: block;
        width: fit-content;
        height: fit-content;
        align-items: flex-start;
        margin-top: 1.563rem;
    }

    &__tooltip-container {
        height: 25px;
        position: relative;

        @media screen and (max-width: $breakpoint-768) {
            display: flex;
        }
    }
    &__small-text-tooltip {
        position: absolute;
        bottom: 0.563rem;
        left: 1.188rem;
    }
    &__tooltip {
        position: absolute;
        top: -0.75rem;
        left: 8rem;
    }

    &__button {
        margin-top: 1.8rem;
        @media screen and (max-width: $breakpoint-768) {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__name-error {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
    &__error-message {
        color: $color-red;
        margin-left: 0.625rem;
        margin-top: 0.3rem;
        font-family: $font-family-inter;
    }
}

.tooltip-container__icon {
    margin-left: 0.3rem;
}
