@import "../abstracts/variables.scss";

.main-layout {
    &__container {
        background-image: url("../../../public/Background.png");
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        margin-bottom: 7.188rem;

        @media screen and (max-width: $breakpoint-550) {
            margin-bottom: 0;
        }
    }
}

.main-left-panel {
    width: 20%;
    min-width: 12.8rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3.25rem;
    margin-left: 1rem;
    margin-right: 1rem;

    &__container {
        width: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 25%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        display: none;
    }
}

.main-middle-panel {
    width: 60%;

    padding-top: 3.25rem;
    @media screen and (max-width: $breakpoint-1599) {
        width: 50%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-550) {
        padding-top: 0;
    }
}

.main-right-panel {
    width: 20%;
    min-width: 12.8rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 3.25rem;
    margin-left: 1rem;
    margin-right: 1rem;

    &__container {
        width: 80%;
        min-width: 14.3rem;
        max-width: 20rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
    }
    &__text {
        width: 88%;
        font-size: $font16;
        font-family: $font-family-inter;
        font-weight: 700;
        @media screen and (max-width: $breakpoint-1900) {
            width: 90%;
        }

        @media screen and (max-width: $breakpoint-1279) {
            font-size: $font14;
        }
    }

    @media screen and (max-width: $breakpoint-1599) {
        width: 25%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        display: none;
    }
}
