@import "../abstracts/variables.scss";

.header-burger-menu {
    width: 64%;
    max-width: 20rem;
    height: 40rem;
    top: 3.8rem;
    position: absolute;
    border: 0.005rem solid $color-light-grey;
    right: 0;
    min-width: 13.5rem;
    max-width: 18.125rem;
    background-color: $color-white;
    z-index: $z-index-second-layer;

    @media screen and (min-width: $breakpoint-1023) {
        display: none;
    }

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 3.75rem;
        font-family: $font-family-inter;
        font-weight: 700;
        font-size: $font14;
        color: $color-darker-blue;
        border-bottom: 0.03rem solid $color-light-grey;
        background-color: $color-white;

        &:hover {
            filter: brightness(0.95);
        }
    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
    }

    &__text {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 75%;
    }
}
