@import "../abstracts/variables.scss";
@import "../abstracts/mixins.scss";

.user-profile-rooms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 50rem;

    @media screen and (max-width: $breakpoint-1599) {
        width: 100%;
    }
    @media screen and (max-width: $breakpoint-1023) {
        width: 80%;
    }
    @media screen and (max-width: $breakpoint-768) {
        width: 90%;
        margin-bottom: 2rem;
    }

    &__title-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2rem;

        @media screen and (max-width: $breakpoint-1350) {
            margin-bottom: 1.75rem;
        }
        @media screen and (max-width: $breakpoint-768) {
            margin-bottom: 1rem;
        }
        @media screen and (max-width: $breakpoint-450) {
            margin-bottom: 0.7rem;
        }
    }

    &__title {
        margin-left: 2rem;
        font-family: $font-family-inter;
        font-size: $font22;
        font-weight: 700;
        color: $color-light-blue;

        @media screen and (max-width: $breakpoint-1023) {
            font-size: $font18;
        }
        @media screen and (max-width: $breakpoint-768) {
            font-size: $font16;
            margin-bottom: 1rem;
        }

        @media screen and (max-width: $breakpoint-450) {
            font-size: $font14;
            margin-bottom: 0.75rem;
        }
    }

    &__empty-item {
        font-size: $font16;
        font-weight: 700;
        font-family: $font-family-inter;
        color: $color-mid-dark-grey;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__item {
        width: 100%;
        background-color: $color-white;
        &:hover {
            filter: $hover-brightness-darker;
        }
    }
}
